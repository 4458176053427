import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import envelope from '../images/envelope.png'
import {
  Container,
  Row,
  Col,
  InputGroup,
  Input,
  Button,
  FormGroup,
  Label,
} from 'reactstrap'
import {
  faCheck,
  faExclamation,
  faExclamationCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
const axios = require('axios')

class NewsLetter extends React.Component {
  constructor(props) {
    super(props)
    this.signup = this.signup.bind(this)
    this.state = {
      email: null,
      success: null,
      message: null,
      showRadio: false,
      vehicles: ['None'],
      showMore: false,
    }
    this.toggleVehicle = this.toggleVehicle.bind(this)
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  handleClickOutside(event) {
    if (
      event.target &&
      event.target.className &&
      event.target.className.includes &&
      !event.target.className.includes('own-a-vehicle') &&
      !event.target.className.includes('form-check-label') &&
      !event.target.className.includes('form-check-input') &&
      !event.target.className.includes('radio-options') &&
      !event.target.className.includes('form-check') &&
      !event.target.className.includes('info')
    ) {
      this.setState({ showRadio: false })
    }
  }

  toggleVehicle(type) {
    console.log(type)
    let vs = this.state.vehicles
    if (type === 'None') {
      vs = ['None']
      this.setState({ vehicles: vs })
    } else {
      vs = vs.filter(v => v !== 'None')
      if (vs.includes(type)) {
        vs = vs.filter(v => v !== type)
      } else {
        vs.push(type)
      }
      this.setState({ vehicles: vs })
    }
    console.log(vs)
  }

  signup() {
    axios(
      `https://cleanairgm.com/.netlify/functions/moo2?email=${this.state.email
      }&vehicles=${this.state.vehicles.join(',')}`
      // `http://tfgm-proxy-api.localhost/moo2.php?email=${this.state.email}&vehicles=${this.state.vehicles.join(',')}`
    ).then(response => {
      this.setState({
        success: response.data.success,
        message: response.data.message,
      })
    })
  }
  render() {
    return (
      <div
        style={{ backgroundColor: this.props.blue ? '#22354B' : '' }}
        ref={this.setWrapperRef}
        className="footer__mailing"
      >
        <Container>
          {this.props.toggle && (
            <button className="footer__close" onClick={this.props.toggle}>
              Close
              <FontAwesomeIcon className="ml-3" icon={faTimes} />
            </button>
          )}

          {this.state.success !== true && (
            <Row className="stay-in-loop text-center text-lg-left">
              <Col sm={12} lg={{ size: 12 }} className="mb-4 mb-lg-0">
                <div className="stay-in-loop__icon-holder">
                  <img
                    src={envelope}
                    alt=""
                    className="stay-in-loop__icon"
                  />
                </div>
                <h1>Sign up for updates</h1>
                <p>
                  Keep up to date with the latest Clean Air Plan news.
                </p>
              </Col>
              <Col sm={12} lg={{ size: 12 }}>
                <Row>
                  <Col
                    md={12}
                    lg={{ size: 6 }}
                    className="text-center text-lg-right mt-2"
                  >
                    <InputGroup>
                      <Input
                        type="text"
                        aria-describedBy="err"
                        autoComplete="email"
                        aria-label="Enter your email address"
                        placeholder="Enter your email address"
                        onChange={t => {
                          this.setState({ email: event.target.value })
                        }}
                      />
                      {/* <InputGroupAddon addonType="append">
                          <Button
                            aria-label="Sign up"
                            onClick={this.signup}
                            className="btn-signup"
                          >
                            Sign up
                          </Button>
                        </InputGroupAddon> */}
                    </InputGroup>
                    {this.state.success !== null && (
                      <p id="err" className="response-message">
                        {this.state.success === false && (
                          <FontAwesomeIcon
                            className="mr-3"
                            icon={faExclamationCircle}
                          />
                        )}
                        {this.state.success === true && (
                          <FontAwesomeIcon className="mr-3" icon={faCheck} />
                        )}
                        {this.state.message}
                      </p>
                    )}
                  </Col>
                  <Col
                    md={12}
                    lg={{ size: 6 }}
                    className="text-center text-lg-right mt-2"
                  >
                    <div className="position-relative">
                      <button onClick={t => {
                        this.setState({ showRadio: !this.state.showRadio })
                      }} type="button" aria-expanded={this.state.showRadio}
                        className="btn btn-input own-a-vehicle" aria-controls="vehicleOptions" id="accordion1id">
                        {this.state.vehicles.filter(v => v !== 'None').join(', ') ? this.state.vehicles.filter(v => v !== 'None').join(', ') : 'Do you own or operate a vehicle? (optional)'}
                      </button>

                      {this.state.showRadio === true && (
                        <div id="vehicleOptions" aria-labelledby="accordion1id" className="radio-options">
                          <fieldset>

                            <p className="info">Select all that apply</p>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  name="radio1"
                                  checked={this.state.vehicles.includes('None')}
                                  onClick={() => this.toggleVehicle('None')}
                                />{' '}
                                None
                              </Label>
                            </FormGroup>

                            <br />

                            {[
                              'Car',
                              'Bus',
                              'Coach',
                              'LGV/Van',
                              'HGV',
                              'Minibus',
                              'Taxi/PHV',
                              'Other',
                            ].map((e, i) => (
                              <FormGroup key={`s${i}`} check>
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    name={`radio${i}`}
                                    checked={this.state.vehicles.includes(e)}
                                    onClick={() => this.toggleVehicle(e)}
                                  />{' '}
                                  {e}
                                </Label>
                              </FormGroup>
                            ))}
                          </fieldset>
                          <button
                            className="btn btn-secondary"
                            onClick={() => {
                              this.setState({ showRadio: !this.state.showRadio })

                            }}
                          >
                            Close
                          </button>
                        </div>

                      )}

                    </div>
                  </Col>
                  <Col sm={12}>
                    <Row>
                      <Col>
                        <p className="disclaimer-text">
                          Transport for Greater Manchester (TfGM) is the Data
                          Controller of personal information provided in this
                          form.
                          {!this.state.readMore && (
                            <button
                              className="read-more-btn"
                              onClick={() => {
                                this.setState({ readMore: true })
                              }}
                            >
                              Read more
                            </button>
                          )}
                          {this.state.readMore && (
                            <span>
                              {' '}
                              We rely on your consent to process your personal
                              information for the purpose of providing you with
                              updates on the Greater Manchester Clean Air Plan
                              and other related information. Your personal data
                              will not be shared with any third party. Your data
                              will be held until you opt out of receiving
                              emails, when it will be added to a suppression
                              list. Information on exercising your rights under
                              data protection law as well as more information
                              about how we process your personal data can be
                              found in our Privacy Policy on the TfGM website at{' '}
                              <a href="http://tfgm.com/privacy-policy">
                                http://tfgm.com/privacy-policy
                              </a>{' '}
                              You can contact our Data Protection Officer by
                              emailing data.protection@tfgm.com.
                            </span>
                          )}
                        </p>
                      </Col>
                      <Col sm={'auto'}>
                        <Button
                          aria-label="Sign up"
                          onClick={this.signup}
                          className="btn-signup-full mt-3"
                        >
                          Sign up
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}

          {this.state.success === true && (
            <Row className="stay-in-loop text-center">
              <Col sm={12} className="text-center">
                <h1 className="m-0 p-0">
                  <img
                    src={envelope}
                    alt=""
                    className="stay-in-loop__icon mr-3"
                  />
                  Thank you, we'll be in touch
                </h1>
                <br />
                {this.props.toggle && (
                  <button
                    className="btn btn-signup-full"
                    onClick={this.props.toggle}
                  >
                    Close
                  </button>
                )}
              </Col>
            </Row>
          )}
        </Container>
      </div>
    )
  }
}

export default NewsLetter
