import React from 'react'
import {
  connectHits,
  InstantSearch,
  connectSearchBox,
  connectHighlight,
  Snippet,
} from 'react-instantsearch-dom'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBorderNone,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import algoliasearch from 'algoliasearch/lite'

const algoliaClient = algoliasearch(
  'EB3K2V2T3G',
  'ab071a9329d9fc8fd91760e5d99d137d'
)

let searchTerm = null

const searchClient = {
  search(requests) {
    const shouldSearch = requests.some(({ params: { query } }) => query !== '')
    if (shouldSearch) {
      return algoliaClient.search(requests)
    }
    return Promise.resolve({
      results: [{ hits: [] }],
    })
  },
  searchForFacetValues: algoliaClient.searchForFacetValues,
}

const Highlight = ({ hit }) => {
  //  return <Snippet hit={hit} attribute="text" />
  if (
    hit &&
    hit._snippetResult &&
    hit._snippetResult.text &&
    Array.isArray(hit._snippetResult.text)
  ) {
    return (
      <div className="">
        {hit._snippetResult.text.map((part, index) => {
          return part.matchLevel !== 'none' ? (
            <span
              dangerouslySetInnerHTML={{
                __html:
                  part.value
                    .replace('<ais-highlight-0000000000>', '<strong>')
                    .replace('</ais-highlight-0000000000>', '</strong>') + ' ',
              }}
            />
          ) : null
        })}
      </div>
    )
  } else {
    return null
  }
}

const CustomHighlight = connectHighlight(Highlight)

const promote = (url, arr) => {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i].url === url) {
      var a = arr.splice(i, 1) // removes the item
      arr.unshift(a[0]) // adds it back to the beginning
      break
    }
  }
}

const customOrder = (hits, search) => {
  const term = search.toLowerCase().trim()

  if (!term) {
    return []
  }

  if ('air pollution'.includes(term)) {
    promote('https://cleanairgm.com/air-pollution#your-health', hits)
    promote('https://cleanairgm.com/air-pollution/', hits)
  }

  if ('effects of air pollution'.includes(term)) {
    promote('https://cleanairgm.com/air-pollution/', hits)
    promote('https://cleanairgm.com/air-pollution#your-health', hits)
  }

  if ('clean air zone'.includes(term)) {
    promote('https://cleanairgm.com/clean-air-zone/', hits)
    hits = hits.filter(h => h.url !== 'https://cleanairgm.com/clean-air-zone/')
    hits.unshift({
      title: 'Clean Air Zone',
      url: 'https://cleanairgm.com/clean-air-zone/',
    })
  }

  if ('health'.includes(term)) {
    promote('https://cleanairgm.com/air-pollution/', hits)
    promote('https://cleanairgm.com/air-pollution#your-health', hits)
  }

  if ('elderly babies adults'.includes(term)) {
    hits = hits.filter(h => h.url !== '/air-pollution#whos-at-risk')
    hits.unshift({
      title: "Who's at risk?",
      url: '/air-pollution#whos-at-risk',
    })
  }

  if ('idling'.includes(term)) {
    hits = hits.filter(
      h => h.url !== '/be-the-change#mythbusting-engine-idling'
    )
    hits.unshift({
      title: 'Mythbusting: Engine idling',
      url: '/be-the-change#mythbusting-engine-idling',
    })
  }

  if ('anti-idling'.includes(term)) {
    hits = hits.filter(
      h => h.url !== '/be-the-change#mythbusting-engine-idling'
    )
    hits.unshift({
      title: 'Mythbusting: Engine idling',
      url: '/be-the-change#mythbusting-engine-idling',
    })
  }

  if ('vehicle checker'.includes(term)) {
    hits = hits.filter(h => h.url !== '/clean-air-plans#vehicle-checker')
    hits.unshift({
      title: 'Vehicle checker',
      url: '/clean-air-plans#vehicle-checker',
    })
  }

  if ('vehicle checker'.includes(term)) {
    hits = hits.filter(h => h.url !== '/clean-air-plans#vehicle-checker')
    hits.unshift({
      title: 'Vehicle checker',
      url: '/clean-air-plans#vehicle-checker',
    })
  }

  if ('charges'.includes(term)) {
    promote('https://cleanairgm.com/bus-and-coach/', hits)
    promote('https://cleanairgm.com/taxi-and-private-hire/', hits)
    promote('https://cleanairgm.com/heavy-goods-vehicles/', hits)
    promote('https://cleanairgm.com/light-goods-vehicles/', hits)
    promote('https://cleanairgm.com/clean-air-plans/', hits)
  }

  if ('fines fined will i be fined'.includes(term)) {
    hits = hits.filter(h => h.url !== '/bus-and-coach/')
    hits.unshift({ title: 'Bus and coach', url: '/bus-and-coach/' })

    hits = hits.filter(h => h.url !== '/taxi-and-private-hire/')
    hits.unshift({
      title: 'Taxi and private hire',
      url: '/taxi-and-private-hire/',
    })

    hits = hits.filter(h => h.url !== '/heavy-goods-vehicles/')
    hits.unshift({
      title: 'Heavy goods vehicles',
      url: '/heavy-goods-vehicles/',
    })

    hits = hits.filter(h => h.url !== '/clean-air-zone/motorcaravan/')
    hits.unshift({
      title: 'Light goods vehicles',
      url: '/light-goods-vehicles/',
    })

    hits = hits.filter(h => h.url !== 'https://cleanairgm.com/clean-air-plans/')
    hits.unshift({
      title: 'Clean air plans',
      url: 'https://cleanairgm.com/clean-air-plans/',
    })
  }

  if ('kids'.includes(term)) {
    promote('https://cleanairgm.com/be-the-change/', hits)

    hits = hits.filter(h => h.url !== 'https://cleanairgm.com/schools/')
    hits.unshift({ title: 'Schools', url: 'https://cleanairgm.com/schools/' })
  }

  if ('stakeholders'.includes(term)) {
    promote('https://cleanairgm.com/clean-air-plans/', hits)
  }

  if ('newsletters email updates'.includes(term)) {
    hits = hits.filter(h => h.url !== 'https://cleanairgm.com/news/')
    hits.unshift({ title: 'News', url: 'https://cleanairgm.com/news/' })

    hits = hits.filter(h => h.url !== '/#newsletter')
    hits.unshift({ title: 'Stay in the loop', url: '/#newsletter' })
  }

  if ('news'.includes(term)) {
    hits = hits.filter(h => h.url !== '/#newsletter')
    hits.unshift({ title: 'Stay in the loop', url: '/#newsletter' })

    hits = hits.filter(h => h.url !== 'https://cleanairgm.com/news/')
    hits.unshift({ title: 'News', url: 'https://cleanairgm.com/news/' })
  }

  if ('latest'.includes(term)) {
    hits = hits.filter(h => h.url !== '/#newsletter')
    hits.unshift({ title: 'Stay in the loop', url: '/#newsletter' })

    hits = hits.filter(h => h.url !== 'https://cleanairgm.com/news/')
    hits.unshift({ title: 'News', url: 'https://cleanairgm.com/news/' })

    hits = hits.filter(
      h => h.url !== 'https://cleanairgm.com/data-hub/monitoring-stations/'
    )
    hits.unshift({
      title: 'Latest air quality',
      url: 'https://cleanairgm.com/data-hub/monitoring-stations/',
    })
  }

  if ('what are government doing what are the government'.includes(term)) {
    hits = hits.filter(h => h.url !== 'https://cleanairgm.com/clean-air-plans/')
    hits.unshift({
      title: 'Clean air plans',
      url: 'https://cleanairgm.com/clean-air-plans/',
    })
  }

  if ('how to reduce air pollution'.includes(term)) {
    hits = hits.filter(h => h.url !== 'https://cleanairgm.com/air-pollution/')
    hits.unshift({
      title: 'Air pollution and you',
      url: 'https://cleanairgm.com/air-pollution/',
    })

    hits = hits.filter(h => h.url !== 'https://cleanairgm.com/be-the-change/')
    hits.unshift({
      title: 'Be the change',
      url: 'https://cleanairgm.com/be-the-change/',
    })
  }

  if ('am i at risk'.includes(term)) {
    hits = hits.filter(h => h.url !== 'https://cleanairgm.com/be-the-change/')
    hits.unshift({
      title: 'Be the change',
      url: 'https://cleanairgm.com/be-the-change/',
    })

    hits = hits.filter(h => h.url !== 'https://cleanairgm.com/air-pollution/')
    hits.unshift({
      title: 'Air pollution and you',
      url: 'https://cleanairgm.com/air-pollution/',
    })
  }

  hits = hits.filter(h => !h.url.includes('dev'));

  return hits
}

const Hits = connectHits(({ hits }) => {
  hits = customOrder(hits, searchTerm)

  return (
    <div>
      {/* Always use a ternary when coercing an array length */}
      {/* otherwise you might print out "0" to your UI */}
      {hits.length ? (
        <div className="menu-hits">
          {hits.map((hit, j) => {
            if (j < 5) {
              return (
                <div key={hit.objectID}>
                  {hit.title && (
                    <Link to={hit.url.replace('https://cleanairgm.com', '')}>
                      <div className="search-hit-menu">
                        <div className="pr-3 w-100">
                          <h4>
                            {hit.title
                              ? hit.title.replace(
                                '| Clean Air Greater Manchester',
                                ''
                              )
                              : 'No title'}
                          </h4>
                        </div>
                      </div>
                    </Link>
                  )}
                </div>
              )
            }
            return null
          })}
          {hits.length > 4 && (
            <Link
              className="btn btn-about mt-3 mb-0"
              to="/search"
              state={{ searchTerm: searchTerm }}
            >
              View more
            </Link>
          )}
        </div>
      ) : null}
    </div>
  )
})

const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => {
  searchTerm = currentRefinement
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center'}}>
      <form noValidate action="" role="search">
        <input
          className="form-control"
          type="search"
          style={{ width: '100px' }}
          placeholder="Search"
          value={currentRefinement}
          onChange={event => refine(event.currentTarget.value)}
        />

      </form>
      {searchTerm.length > 0 &&
        <button aria-label="Clear search" style={{ border: 'none', backgroundColor: 'transparent', padding:'5px', marginLeft:'3px' }} onClick={() => refine('')}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      }
    </div>
  )
}

const CustomSearchBox = connectSearchBox(SearchBox)

class Search extends React.Component {
  render() {
    return (
      <div className="menu-search">
        <InstantSearch
          indexName={"netlify_8ad80982-1dc9-4094-bab4-52741611d196_live_all"}
          searchClient={searchClient}
        >
          <CustomSearchBox />
          <Hits />
        </InstantSearch>
      </div>
    )
  }
}

export default Search
