import React, { useEffect } from 'react'

const JSTags = () => {
  useEffect(() => {
    window.cookieConsentConfig = {
      // optional number: When should the user be asked again to provide consent.
      // Default is 365 days as per GDPR.
      cookieExpiry: 365,

      // optional string: Default is the current domain. Useful if you want cookies accepted
      // on subsite.example.com to apply to example.com too. In which case set this to
      // 'example.com' on the sub-site. Otherwise it's best to exclude this option.
      // cookieDomain: "example.com",

      // optional string: If your site has a strict Content Security Policy
      // (https://csp.withgoogle.com/docs/strict-csp.html) you can provide the nonce here.
      // nonce: '098F6BCD4621D373CADE4E832627B4F6',

      // optional boolean: Default is true. If true, generic Youtube or Vimeo cookie info will be shown
      // in the "Cookies used on our site" section.
      showYoutubeInfo: true,
      showVimeoInfo: true,

      // optional HTML: Default copy is used if these options are not.
      copy: {
        // Copy under the 'Essential cookies' section.
        essentialDescription:
          'Some website functions, such as watching videos on the website, require cookies to work. Without these cookies our website won’t work properly.',

        // Copy under the 'Analytics cookies' section.
        analyticsDescription:
          'These cookies collect information about how people use our site, such as the number of visits a page gets. These cookies do not collect data that can identify you as an individual. We use this information to help us improve the website.',

        // Copy under the 'Marketing cookies' section.
        marketingDescription:
          'These cookies are set by TfGM and our selected partners to show you relevant content and adverts from TfGM on other websites and apps you use. Without them you will still see ads but they may be less relevant to you.',
      },

      // The cookies array below is for display purposes only. This is what is displayed
      // on the 'Cookies used on our site' screen.
      cookies: [
        {
          // required string: The name of the cookie set in the browser.
          cookieName: '_fbp',

          // required string: The cookie type. One of either 'essential', 'analytics' or 'marketing'.
          // This determined which section of the 'Cookies used on our site' screen this is displayed in.
          type: 'marketing',

          // required string: A user friendly title for the cookie.
          title: 'Facebook Pixel',

          // required string: A user friendly description of the cookie's purpose.
          description:
            'Help deliver our marketing content to people who have already visited our website, when they are on Facebook or a digital platform powered by Facebook Advertising.',

          // required string: The known expiry of the 3rd party cookie.
          duration: '3 months',

          // optional string: Details and/or links to 3rd party privacy policies (any HTML can go here).
          privacyPolicy:
            '<a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>',
        },
        {
          cookieName: '_ga',
          type: 'analytics',
          title: 'Google Analytics',
          description: 'Used to distinguish users',
          duration: '2 years',
          privacyPolicy:
            '<a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>',
        },
        {
          cookieName: '_ga_W0BYYK6TT4',
          type: 'analytics',
          title: 'Google Analytics',
          description: 'Used to persist session state.',
          duration: '2 years',
          privacyPolicy:
            '<a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>',
        },
        {
          cookieName: '_gid',
          type: 'analytics',
          title: 'Google Analytics',
          description: 'Used to distinguish users.',
          duration: '1 day',
          privacyPolicy:
            '<a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>',
        },
        {
          cookieName: '_gat_UA-7110856-9',
          type: 'analytics',
          title: 'Google Analytics',
          description: 'Used to throttle request rate.',
          duration: '1 minute',
          privacyPolicy:
            '<a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>',
        },
        {
          cookieName: 'schoolkit',
          type: 'essential',
          title: 'Schools toolkit',
          description: 'Remembers if you have signed up for schools toolkit.',
          duration: '1 year',
          privacyPolicy:
            '<a href="https://cleanairgm.com/cap-privacy-policy">https://cleanairgm.com/cap-privacy-policy</a>',
        },
      ],
    }
    // const script2 = document.createElement("script");
    // script2.src = "/config.js";
    // document.head.appendChild(script2);

    const script3 = document.createElement('script')
    script3.src = '/gtag.js'
    script3.type = 'cookie-consent-required'
    script3.setAttribute('data-cookie-type', 'essential')
    document.body.appendChild(script3)

    const script4 = document.createElement('script')
    script4.src = '/fb.js'
    script4.type = 'cookie-consent-required'
    script4.setAttribute('data-cookie-type', 'marketing')
    document.body.appendChild(script4)

    const script = document.createElement('script')
    script.src = 'https://cdn.tfgm.com/static/js/bundle.js'
    script.defer = true
    document.body.appendChild(script)
  }, [])

  return <></>
}

export default JSTags
