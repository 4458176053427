import React from 'react'
import { Link } from 'gatsby'
import { graphql, StaticQuery } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import logoFooter from '../images/footer-logo.svg'
import Search from './search'

class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
    }
  }

  render() {
    const navigationItems = [
      {
        name: 'Clean Air Plan',
        url: '/clean-air-plan',
      },
      {
        name: 'About air pollution',
        url: '/air-pollution',
      },
      {
        name: 'Be the change',
        url: '/be-the-change',
      },
      {
        name: 'FAQs',
        url: '/faqs',
      },
    ]

    return (
      <div>
        <nav
          role="navigation"
          style={{ position: 'relative' }}
          className="d-none d-lg-block"
        >

          {/* <Link className="dealership-button" to="/dealerships">
            Become an Accredited Dealership
          </Link> */}
          {/* <Link
                  to={'/clean-air-consultation'}
                  onClick={() =>
                    this.setState({
                      active: false,
                    })
                  }
                  className='btn btn-content btn-content--blue small'
                >Consultation</Link> */}

          <ul>
            <li>
              <Link className='skipNav' to="#mainContent">Skip navigation</Link>
            </li>
            {navigationItems.map((n, i) => (
              <li key={`ni${i}`}>
                <Link activeClassName="active" to={n.url}>
                  {n.name}
                </Link>
              </li>
            ))}
          </ul>
          <Search />
        </nav>
        <div className="d-lg-none mobile-nav">
          {/* <Link
                  to={'/clean-air-consultation'}
                  onClick={() =>
                    this.setState({
                      active: false,
                    })
                  }
                  className='btn btn-mobile-nav'
                >Consultation</Link> */}

          <button
            aria-label="Mobile navigation menu"
            className="mobile-nav__btn"
            onClick={() => {
              this.setState({ active: !this.state.active })
            }}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>
        {this.state.active && (
          <div className="mobile-menu">
            <div className="mobile-menu__header">
              <Link
                to={'/'}
                onClick={() =>
                  this.setState({
                    active: false,
                  })
                }
              >
                <img className="mobile-menu__header__logo" src={logoFooter} />
              </Link>

              <button
                className="mobile-menu__header__btn"
                onClick={() => {
                  this.setState({ active: !this.state.active })
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <ul className="mt-5 text-center mb-5">

              <li>
                <Link to="/dealerships">Become an Accredited Dealership</Link>
              </li>
              {/* <li>
                      <Link
                        to={'/clean-air-consultation'}
                        onClick={() =>
                          this.setState({
                            active: false,
                          })
                        }
                        className='btn btn-content btn-content--blueMobile'
                      >Consultation</Link>

                    </li> */}
              {navigationItems.map((n, i) => (
                <li key={`mi${i}`}>
                  <Link
                    onClick={() =>
                      this.setState({
                        active: false,
                      })
                    }
                    activeClassName="active"
                    to={n.url}
                  >
                    {n.name}
                  </Link>
                </li>
              ))}

              <li>
                <Link
                  onClick={() =>
                    this.setState({
                      active: false,
                    })
                  }
                  activeClassName="active"
                  to={'/search'}
                >
                  Search
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    )
  }
}

export default Navigation
