import React, { useState } from 'react'
import styles from '../scss/styles.scss'
import Navigation from './navigation'
import logo from '../images/logo-plain.png'
import wave from '../images/footer-wave.png'
import waveTran from '../images/footer-wave-tran.png'
import logoFooter from '../images/footer-logo-plain.png'
import Helmet from 'react-helmet'
import NewsLetter from './newsletter'
import { withPrefix } from 'gatsby'

import {
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Input,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import JSTags from './js-tags'
const axios = require('axios')

class Template extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal3: false,
    }
    this.toggleModal3 = this.toggleModal3.bind(this)
  }

  toggleModal3() {
    this.setState({ modal3: !this.state.modal3 })
  }

  render() {
    const { location, children } = this.props
    let header

    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }

    return (
      <>
        <JSTags />
        <Helmet>
          <meta
            name="facebook-domain-verification"
            content="aq3csrg44i37hlq7douuprandcilfd"
          />




        </Helmet>
        <div id="t">
          <div>
            <Container>
              <div className="header">
                <Link aria-label='Clean air for greater Manchester homepage' to="/">
                  <img className="logo" src={logo} alt="" />
                </Link>
                <Navigation
                  location={
                    this.props &&
                      this.props.location &&
                      this.props.location.pathname
                      ? this.props.location.pathname
                      : null
                  }
                  toggleModal3={this.toggleModal3}
                />
              </div>
            </Container>
          </div>
          <main>
            {children}
          </main>
          <footer>
            <div className="footer">
              {!(this.props.hideNewsLetter || this.props.consultationFooter) && (
                <NewsLetter />
              )}
              {this.props.consultationFooter && (
                <div class="consultation-footer">
                  <Container>
                    <Row>
                      <Col className="text-center">
                        <h2>Take part in the consultation</h2>
                        <p>
                          Anyone can take part in the consultation. Please share
                          your views on the proposals to make our air cleaner and
                          healthier for everyone.
                        </p>
                        <a
                          className="btn btn-cons btn-cons--white"
                          href="https://bit.ly/36BIWu9"
                          target="_blank"
                        >
                          Fill out the questionnaire
                        </a>
                      </Col>
                    </Row>
                  </Container>
                </div>
              )}

              <div
                className="footer__wave"
                style={this.props.home ? { backgroundColor: '#f8f8f8' } : null}
              >
                <img src={this.props.hideNewsLetter ? waveTran : wave} alt="" />
              </div>
              <div className="footer__links">
                <Container>
                  <Row>
                    <Col sm={12} lg={6} className="mb-4 mb-lg-0">
                      <img
                        className="footer-logo"
                        alt="Clean Air logo"
                        src={logoFooter}
                      />
                    </Col>
                    <Col lg={2} sm={6}>
                      <h2>Helpful Links</h2>
                      <ul>
                        <li>
                          <Link to={'/data-hub'}>Data hub</Link>
                        </li>
                        <li>
                          <Link to={'/'}>Home</Link>
                        </li>
                        <li>
                          <Link to={'/site-map'}>Site map</Link>
                        </li>
                      </ul>
                    </Col>
                    <Col lg={2} sm={6} className="pt-lg-3 mt-lg-4">
                      <ul>
                        <li>
                          <Link to={'/about'}>About us</Link>
                        </li>
                        <li>
                          <Link to={'/news'}>News</Link>
                        </li>
                        <li>
                          <Link to={'/contact'}>Get in touch</Link>
                        </li>
                      </ul>
                    </Col>
                    <Col lg={2} sm={12} className="mt-3 mt-lg-0">
                      <h2>Connect with us</h2>
                      <a
                        aria-label="Twitter profile"
                        href="https://twitter.com/cleanairgm"
                      >
                        <FontAwesomeIcon icon={faTwitter} />
                      </a>
                      <a
                        aria-label="Facebook profile"
                        href="https://facebook.com/cleanairgm"
                      >
                        <FontAwesomeIcon icon={faFacebookF} />
                      </a>
                    </Col>
                  </Row>
                  <Row className="mt-lg-5 mt-3 footer__links__sub">
                    <Col sm={12} lg={6}>
                      <span className={'d-lg-none d-inline'}>
                        Copyright Clean Air Greater Manchester ©
                        <span className="ml-1 mr-1 d-inline-block">|</span>
                      </span>
                      <Link to={'/terms-and-conditions'}>
                        Terms &amp; conditions
                      </Link>
                      <span className="ml-2 mr-2 d-inline-block">|</span>
                      <a href="/privacy-policies">Privacy policies</a>
                      <span className="ml-1 mr-1 d-inline-block">|</span>
                      <Link to={'/disclaimer'}>Disclaimer</Link>
                      <span className="ml-1 mr-1 d-inline-block">|</span>

                      <button
                        style={{
                          border: 'none',
                          backgroundColor: 'transparent',
                          color: 'white',
                        }}
                        onClick={() => {
                          window.openCookieConsent()
                        }}
                      >
                        Cookies
                      </button>
                      <span className="ml-1 mr-1 d-inline-block">|</span>
                      <Link to={'/accessibility'}>Accessibility</Link>
                    </Col>
                    <Col lg={6} className="text-right d-none d-lg-block">
                      Copyright Clean Air Greater Manchester ©
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </footer>
          <div>
            <Modal
              fade={false}
              centered
              isOpen={this.state.modal3}
              toggle={this.toggleModal3}
              contentClassName="modal3"
              wrapClassName="modal3"
            >
              <ModalBody>
                <NewsLetter toggle={this.toggleModal3} />
              </ModalBody>
            </Modal>
          </div>
        </div>
      </>
    )
  }
}

export default Template
